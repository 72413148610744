import NextImage from "next/image";
import {CloudinaryLoader} from "../../utils/images";

interface IImageProps {
    id: string;
    width: number;
    height: number;
    altText?: string;
    sizes?: string;
}

export default function ArticleImage({id, width, height, altText, sizes}: IImageProps) {
    return (
        <NextImage
            loader={CloudinaryLoader}
            layout={"responsive"}
            alt={altText}
            width={width}
            height={height}
            sizes={sizes}
            src={id} />
    )
}