import {IArticle} from "../../types";
import {createStyles, makeStyles} from "@material-ui/styles";
import {darken, lighten, Theme, Typography} from "@material-ui/core";
import clsx from "clsx";
import ArticleImage from "./articleImage";
import Link from "next/link";
import Routes from "../../routes";
import Gravatar from "../avatars/gravatar";
import React, {Fragment} from "react";
import {dateString} from "../../utils/formatters";
import StandardButton from "../buttons/standardButton";

interface IProps {
    article: IArticle;
    className?: string;
    avatarSize?: number;
    showAuthorsAndReadingTime?: boolean;
    showAvatar?: boolean;
    showReadMore?: boolean;
}

export default function ArticleTile({article, className, showAuthorsAndReadingTime=true, showAvatar=true, avatarSize=35, showReadMore=false}: IProps) {
    const classes = styles();

    if(!article) {
        return null;
    }

    return (
        <div className={clsx(classes.tile, className)}>
            <div className={classes.hero}>
                <Link prefetch={false} passHref={true} href={{
                    pathname: Routes.Articles.Article,
                    query: {slug: article.slug}
                }}>
                    <a>
                        <ArticleImage
                            id={article.heroImage?.publicId}
                            width={article.heroImage?.width}
                            height={article.heroImage?.height} />
                    </a>
                </Link>
            </div>
            <div className={clsx(classes.meta, "meta")}>
                <Typography variant={"h2"} className={"title"}>
                    <Link prefetch={false} passHref={true} href={{
                        pathname: Routes.Articles.Article,
                        query: {slug: article.slug}
                    }}>
                        <a>
                            {article.title}
                        </a>
                    </Link>
                </Typography>
                <div className={classes.summary}>
                    <Typography>
                        {article.summary}
                    </Typography>
                </div>
            </div>
            {showAuthorsAndReadingTime && (
                <div className={classes.authorsReadingTime}>
                    {showAvatar && (
                        <div className={classes.avatars}>
                            {article.authors?.map(author => (
                                <Gravatar
                                    key={author.id}
                                    className={clsx(classes.avatar, "avatar")}
                                    email={author.email}
                                    width={avatarSize} />
                            ))}
                        </div>
                    )}
                    <div className={classes.namesContainer}>
                        <div className={classes.authorNames}>
                            <Typography className={"names"}>
                                {article.authors.map(auth => `${auth.firstName} ${auth.lastName}`).join(", ")}
                            </Typography>
                        </div>
                        <div className={classes.dateAndReadingTime}>
                            {!!article.published && (
                                <Fragment>
                                    <div>
                                        <Typography className={"published"}>
                                            {dateString(article.published!, true)}
                                        </Typography>
                                    </div>
                                    <div>
                                        ·
                                    </div>
                                </Fragment>
                            )}
                            <div>
                                <Typography className={"published"}>
                                    {article.activeVersion?.readingTime}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showReadMore && (
                <div className={classes.readMore}>
                    <Link prefetch={false} passHref={true} href={{
                        pathname: Routes.Articles.Article,
                        query: {slug: article.slug}
                    }}>
                        <StandardButton size={"small"}>
                            Read More
                        </StandardButton>
                    </Link>
                </div>
            )}
        </div>
    )
}

const styles = makeStyles((theme: Theme) => {
    const dark = theme.palette.mode === "dark";
    return createStyles({
        tile: {
            display: "grid",
            // height: "100%",
            overflow: "hidden",
            // gridTemplateRows: "minmax(100px, 1fr) auto auto",
            gridTemplateRows: "auto 1fr auto",
            alignSelf: "stretch",
            backgroundColor: dark
                ? lighten(theme.palette.background.default, 0.05)
                : darken(theme.palette.background.default, 0.03)
            // border: `dotted 1px ${lighten(theme.palette.background.default, 0.1)}`,
        },
        hero: {
            overflow: "hidden",
            maxHeight: "100%",
            "& img": {
                objectFit: "cover",
                width: "auto",
            }
        },
        meta: {
            padding: theme.spacing(1.5, 1.5),
            alignSelf: "start",
            "& .MuiTypography-h2": {
                color: dark ? theme.palette.primary.main : theme.palette.text.primary
            }
        },
        summary: {
            marginTop: theme.spacing(0.75),
            "& .MuiTypography-root": {
                color: theme.palette.text.secondary
            }
        },
        authorsReadingTime: {
            display: "grid",
            gridTemplate: "minmax(0, 1fr) / auto minmax(0, 1fr)",
            gap: theme.spacing(),
            padding: theme.spacing(0, 1, 1, 1)
        },
        avatars: {
            display: "flex",
            flexFlow: "row nowrap"
        },
        avatar: {
            "& > img": {
                cursor: "default",
            },
            "&+&": {
                marginLeft: "-10px"
            }
        },
        namesContainer: {
            alignSelf: "center"
        },
        authorNames: {
            "& .MuiTypography-root.names": {
                fontSize: "0.9rem",
                lineHeight: 1.2,
                fontWeight: theme.typography.fontWeightMedium,
                color:dark
                    ? lighten(theme.palette.background.default, 0.55)
                    : lighten(theme.palette.text.secondary, 0.2)
            }
        },
        dateAndReadingTime: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: theme.spacing(0.5),
            marginTop: theme.spacing(-0.5),
            "& .MuiTypography-root.published": {
                fontSize: theme.typography.caption.fontSize,
                color:dark
                    ? lighten(theme.palette.background.default, 0.45)
                    : lighten(theme.palette.text.secondary, 0.3)
            }
        },
        readMore: {
            padding: theme.spacing(0, 2, 2, 2)
        }
    });
});