import {IArticle} from "../../types";
import ArticleTile from "./articleTile";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme, useMediaQuery} from "@material-ui/core";
import {useMemo} from "react";

interface IProps {
    articles: IArticle[];
}

export default function FeaturedArticles({articles}: IProps) {
    const classes = styles();
    const isLargerDevice = useMediaQuery((theme:Theme) => theme.breakpoints.up(700));

    const subset = useMemo(() => {
        return isLargerDevice ? articles : articles.slice(0, 4);
    }, [articles, isLargerDevice]);

    return (
        <section className={classes.root}>
            {subset.map(a => (
                <ArticleTile
                    key={a.id}
                    article={a}
                    showAuthorsAndReadingTime={false}
                    showReadMore={true}
                    className={classes.tile}
                />
            ))}
        </section>
    )
}

const styles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: "grid",
            gridTemplateColumns: "repeat( auto-fit, minmax(250px, 1fr) )",
            gap: theme.spacing(3),
            marginTop: theme.spacing(4),
            [theme.breakpoints.up(700)]: {
                gridTemplateColumns: "repeat(2, minmax(0, 50%))",
            },
            [theme.breakpoints.up(1000)]: {
                gridTemplateColumns: "repeat(4, minmax(0, 25%))",
            }
        },
        tile: {
            borderRadius: "8px",
            "& .MuiTypography-h2.title": {
                fontSize: "1.3rem"
            },
            "& .meta": {
                padding: theme.spacing( 2)
            }
        }
    })
});