import {Fragment} from "react";
import StandardPage from "../components/layout/standardPage";
import {Box, Button, darken, lighten, Theme, Typography} from "@material-ui/core";
import FeatureTile from "../components/featureTile";
import Link from "next/link";
import FeaturedProducts from "../components/products/featuredProducts";
import {cameras, lenses, lights, accessories} from "../components/products/featured";
import Routes from "../routes";
import {createStyles, makeStyles} from "@material-ui/styles";
import {AccessTime, FlashOn, StarBorder} from "@material-ui/icons";
import OrganisationSchema from "../components/schema/organisationSchema";
import IndexCard from "../components/schema/indexCard";
import HelpButton from "../components/buttons/helpButton";
import clsx from "clsx";
import {NextPage} from "next/types";
import {IArticle} from "../types";
import {GetStaticProps} from "next";
import FeaturedArticles from "../components/articles/featured-articles";
import {createApolloClient} from "../apolloClient";
import {GetArticles, GetArticlesVariables} from "../__generated__/GetArticles";
import {GET_ARTICLES} from "../queries";

interface IProps {
    articles: IArticle[];
}

const Home: NextPage<IProps> = ({articles}) => {
    const classes = styles();
    return (
        <Fragment>
            <StandardPage
                title={"Equipment rental for still and motion photography"}
                description={"Offshoot Rentals is a trusted production partner in Melbourne, supplying equipment and support to a wide range of productions since 2010."}>
                <Box mt={3} mb={6} p={[2, 4]} className={classes.page}>
                    <OrganisationSchema/>
                    <IndexCard/>
                    <section className={classes.constrained}>
                        <FeatureTile/>
                        <Box my={[2, 6]} px={[2, 4, 14]}>
                            <Typography variant={"h2"} className={classes.blurb}>
                                Offshoot Rentals is a trusted production partner in Melbourne, supplying equipment
                                and support to a wide range of productions since 2010.
                            </Typography>
                        </Box>
                        <section className={clsx(classes.spContainer)}>
                            <div className={classes.sp}>
                                <div>
                                    <div className={classes.headerContainer}>
                                        <Box mt={1} mr={0.5}>
                                            <FlashOn fontSize={"inherit"}/>
                                        </Box>
                                        <Typography variant={"h3"} className={classes.instantQuotes}>
                                            Instant Quotes 24/7
                                        </Typography>
                                    </div>
                                    <Typography className={classes.spBlurb}>
                                        Get a quote instantly - whenever it suits you. All you need to do is browse our
                                        range
                                        of gear and add what you need to a quote. When you accept our quote,
                                        the gear is held for you automatically.
                                    </Typography>
                                </div>
                                <Box>
                                    <HelpButton
                                        text={"Learn How"}
                                        url={Routes.Help.GetQuote}
                                    />
                                </Box>
                            </div>
                            <div className={classes.sp}>
                                <div>
                                    <div className={classes.headerContainer}>
                                        <Box mt={0.9} mr={1}>
                                            <AccessTime fontSize={"inherit"}/>
                                        </Box>
                                        <Typography variant={"h3"} className={classes.instantQuotes}>
                                            Live Availability
                                        </Typography>
                                    </div>
                                    <Typography className={classes.spBlurb}>
                                        Add products to a quote then choose your dates. We will show you
                                        if the gear you want is available for that period based on
                                        whether other customer jobs are currently holding it.
                                    </Typography>
                                </div>
                                <Box>
                                    <HelpButton
                                        text={"Learn How"}
                                        url={Routes.Help.GetQuote}
                                    />
                                </Box>
                            </div>
                            <div className={classes.sp}>
                                <div>
                                    <div className={classes.headerContainer}>
                                        <Box mt={0.9} mr={1}>
                                            <StarBorder fontSize={"inherit"}/>
                                        </Box>
                                        <Typography variant={"h3"} className={classes.instantQuotes}>
                                            New Customer?
                                        </Typography>
                                    </div>
                                    <Typography className={classes.spBlurb}>
                                        Get started in less than two minutes. We just need some basic details
                                        for you to create quotes. Prior to accepting your
                                        first quote, we&lsquo;ll need you to add some ID to your
                                        account.
                                    </Typography>
                                </div>
                                <Box>
                                    <Link href={Routes.Accounts.Create} prefetch={false} passHref={true}>
                                        <Button
                                            data-function={"create-account"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            component={"a"}>
                                            Create an account
                                        </Button>
                                    </Link>
                                </Box>
                            </div>
                        </section>
                    </section>
                    <Box mt={7} className={clsx(classes.featureSection)}>
                        <section className={classes.sectionContainer}>
                            <Typography variant={"h3"} className={classes.featureSectionTitle}>
                                Camera Hire
                            </Typography>
                            <Box>
                                <Typography className={classes.featureSectionText}>
                                    We offer cameras for every project, from high-end productions
                                    using professional ARRI digital cinema cameras to smaller shoots with convenient mirrorless cameras.
                                    As with our other rental equipment, our cameras are checked to a professional standard
                                    between jobs - not simply thrown back on the shelf for the next job.
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Link
                                    passHref={true}
                                    prefetch={false}
                                    href={{
                                        pathname: Routes.Products.Category,
                                        query: {slug: "cameras"}
                                    }}>
                                    <Button
                                        component={"a"}
                                        variant={"outlined"}
                                        color={"secondary"}>
                                        See all cameras
                                    </Button>
                                </Link>
                            </Box>
                        </section>
                        <Box className={classes.featuredProducts}>
                            <FeaturedProducts products={cameras}/>
                        </Box>
                    </Box>
                    <Box mt={[6, 10]} className={clsx(classes.featureSection)}>
                        <section className={classes.sectionContainer}>
                            <Typography variant={"h3"} className={classes.featureSectionTitle}>
                                Lens Hire
                            </Typography>
                            <Typography className={classes.featureSectionText}>
                                We have lenses in a range of mounts - ARRI LPL, ARRI PL, Canon RF, Canon
                                EF and Sony E.
                                Our lenses are tested on an industry standard lens collimator and projector, and also
                                against test charts.
                            </Typography>
                            <Box mt={2}>
                                <Link
                                    passHref={true}
                                    prefetch={false}
                                    href={{
                                        pathname: Routes.Products.Category,
                                        query: {slug: "lenses"}
                                    }}>
                                    <Button
                                        component={"a"}
                                        variant={"outlined"}
                                        color={"secondary"}>
                                        See all lenses
                                    </Button>
                                </Link>
                            </Box>
                        </section>
                        <Box className={classes.featuredProducts}>
                            <FeaturedProducts products={lenses}/>
                        </Box>
                    </Box>
                    <Box mt={[6, 10]} className={classes.featureSection}>
                        <section className={classes.sectionContainer}>
                            <Typography variant={"h3"} className={classes.featureSectionTitle}>
                                Lighting Hire
                            </Typography>
                            <Box>
                                <Typography className={classes.featureSectionText}>
                                    We offer you continuous and strobe lighting from quality, reliable brands - not lighting
                                    from brands you&lsquo;ve never heard of with inconsistent results.
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Link
                                    passHref={true}
                                    prefetch={false}
                                    href={{
                                        pathname: Routes.Products.Category,
                                        query: {slug: "lighting"}
                                    }}>
                                    <Button
                                        component={"a"}
                                        variant={"outlined"}
                                        color={"secondary"}>
                                        See all lighting
                                    </Button>
                                </Link>
                            </Box>
                        </section>
                        <Box className={classes.featuredProducts}>
                            <FeaturedProducts products={lights}/>
                        </Box>
                    </Box>
                    <Box mt={[6, 10]} className={classes.featureSection}>
                        <section className={classes.sectionContainer}>
                            <Typography variant={"h3"} className={classes.featureSectionTitle}>
                                Accessory Hire
                            </Typography>
                            <Box>
                                <Typography className={classes.featureSectionText}>
                                    Besides the core items, we also have a decent range of accessories required for all
                                    sorts of productions.
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Link
                                    passHref={true}
                                    prefetch={false}
                                    href={{
                                        pathname: Routes.Products.AllCategories
                                    }}>
                                    <Button variant={"outlined"} color={"secondary"}>
                                        Browse all products
                                    </Button>
                                </Link>
                            </Box>
                        </section>
                        <Box className={classes.featuredProducts}>
                            <FeaturedProducts products={accessories}/>
                        </Box>
                    </Box>
                    <Box mt={[6, 10]} className={classes.featureSection}>
                        <section className={classes.sectionContainer}>
                            <Typography variant={"h3"} className={classes.featureSectionTitle}>
                                Do More With Us
                            </Typography>
                            <Box>
                                <Typography className={classes.featureSectionText}>
                                    We want to help you get things done quickly, and we&lsquo;ve built
                                    tools to make that happen. Here are some features that let you help yourself,
                                    rather than waiting for us to get back to you during business hours.
                                </Typography>
                            </Box>
                        </section>
                    </Box>
                    <section className={classes.articles}>
                        <FeaturedArticles articles={articles} />
                    </section>
                </Box>
            </StandardPage>
        </Fragment>
    )
}

const styles = makeStyles((theme: Theme) => {
    const lightBase = "#c7a600";
    const dark = theme.palette.mode === "dark";
    const stripeLight = dark ? lighten(theme.palette.background.default, 0.05) : lighten(lightBase, .97);
    const adjustStripe = (factor: number) => {
        return dark ? lighten(theme.palette.background.default, factor-(factor*0.6)) : lighten(lightBase, factor);
    }
    return createStyles({
        page: {
            width: "100%",
            overflowX: "hidden",
        },
        constrained: {
            maxWidth: "2000px",
            marginLeft: "auto",
            marginRight: "auto"
        },
        sectionContainer: {
            maxWidth: "1780px",
            marginLeft: "auto",
            marginRight: "auto"
        },
        blurb: {
            fontWeight: theme.typography.fontWeightBold,
            color: dark ? theme.palette.secondary.main : theme.palette.text.primary,
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent",
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // backgroundImage: dark
            //     ? `linear-gradient(50deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`
            //     : "linear-gradient(50deg, #75644d, #354b74)",
        },
        headerContainer: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            fontSize: theme.typography.h1.fontSize,
            "& .MuiSvgIcon-root": {
                color: dark ? theme.palette.text.secondary : "black"
            },
            borderBottom: "currentColor dotted 1px",
            margin: theme.spacing(0,0,2,0)
        },
        spContainer: {
            marginTop: theme.spacing(5),
            display: "grid",
            gridTemplateColumns: "1fr",
            padding: theme.spacing(0),
            gap: theme.spacing(2.5),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 10)
            },
            [theme.breakpoints.up(1145)]: {
                gridTemplateColumns: "repeat(3, minmax(0,33.33%))",
                gap: theme.spacing(4),
            }
        },
        sp: {
            overflow: "hidden",
            // backgroundImage: `linear-gradient(135deg, ${stripeLight} 25%, ${stripeDark} 25%, ${stripeDark} 50%, ${stripeLight} 50%, ${stripeLight} 75%, ${stripeDark} 75%, ${stripeDark} 100%)`,
            backgroundImage: `linear-gradient(to bottom, ${adjustStripe(0.3)} 0 10px, ${stripeLight} 10px 88%, ${adjustStripe(0.85)} 88% 92%, ${adjustStripe(0.7)} 92% 96%, ${adjustStripe(0.5)} 96% 100%)`,
            // backgroundSize: "56.57px 56.57px",
            // backgroundSize: "150px 150px",
            // background: dark
            //     ? `radial-gradient(${lighten(theme.palette.background.default, 0.05)}, ${lighten(theme.palette.background.default, 0.08)})`
            //     : `linear-gradient(${darken(theme.palette.background.default, 0.02)},${darken(theme.palette.background.default, 0.04)})`,
            // background: dark
            //     ? darken(theme.palette.background.default, 0.12)
            //     : darken(theme.palette.background.default, 0.02),
            borderRadius: "4px",
            // border: `dotted 2px ${theme.palette.secondary.main}`,
            display: "grid",
            gridTemplateRows: "1fr auto",
            gap: theme.spacing(2),
            padding: theme.spacing(2, 2.5, 9, 2.5)
        },
        spBlurb: {
            fontSize: "115%",
            color: dark
                ? lighten(theme.palette.background.default, 0.55)
                : darken(theme.palette.background.default, 0.65)
        },
        instantQuotes: {
            fontSize: "80%"
        },
        featureSection: {
            padding: theme.spacing(0, 2),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 14)
            }
        },
        featureSectionTitle: {
            color: theme.palette.text.primary,
            fontSize: theme.typography.h1.fontSize
        },
        featureSectionText: {
            fontSize: theme.typography.h4.fontSize,
            color: theme.palette.text.secondary,
        },
        featuredProducts: {
            marginTop: theme.spacing(4),
            marginLeft: theme.spacing(-4),
            marginRight: theme.spacing(-4),
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(-18),
                marginRight: theme.spacing(-18)
            }
        },
        articles: {
            // [theme.breakpoints.up('md')]: {
            //     marginLeft: theme.spacing(-6),
            //     marginRight: theme.spacing(-6)
            // }
        }
    })
});

export const getStaticProps: GetStaticProps<IProps> = async () => {
    const apollo = createApolloClient();
    const result = await apollo.query<GetArticles,GetArticlesVariables>({
        query: GET_ARTICLES,
        fetchPolicy: "network-only",
        variables: {
            filters: {
                slugs: [
                    "finding-products-fast",
                    "getting-an-instant-quote",
                    "creating-an-account",
                    "rebooking-gear-from-an-earlier-job",
                    "getting-a-payment-link",
                    "finding-an-invoice",
                    "saving-a-quote-to-your-history",
                    "use-favourite-lists-to-save-time"
                ]
            }
        }
    });

    return {
        props: {
            articles: result.data?.articles ?? []
        },
        revalidate: 60*5
    }
}

export default Home;