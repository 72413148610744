import React, {useRef, useEffect, useState, useCallback} from "react";
import CloudinaryImage from "../images/CloudinaryImage";
import {Box, darken, lighten, Theme, Typography} from "@material-ui/core";
import {motion, MotionValue, useMotionValue} from "framer-motion";
import Routes from "../../routes";
import {useRouter} from "next/router";
import {useDebounce} from "@react-hook/debounce";
import {createStyles, makeStyles} from "@material-ui/styles";
import Link from "next/link";

export interface IFeaturedProduct {
    name: string;
    slug?: string;
    cloudinaryImageId: string;
    category: string;
    height?: number;
    width?: number;
}

export interface IFeaturedProductsProps {
    products: IFeaturedProduct[];
}

export default function FeaturedProducts({products}: IFeaturedProductsProps) {
    const classes = containerStyles();
    const constraints = useRef(null);
    const router = useRouter();
    const x = useMotionValue<number>(0);
    const [disableScroll, setDisableScroll] = useState<boolean>(false);

    useEffect(() => {
        if(!disableScroll) {
            return;
        }
        const handleScroll = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
        }
        document.documentElement.addEventListener("touchstart", handleScroll, {passive: false});
        document.documentElement.addEventListener("touchmove", handleScroll, {passive: false});
        return () => {
            document.documentElement.removeEventListener("touchstart", handleScroll);
            document.documentElement.removeEventListener("touchmove", handleScroll);
        }
    }, [disableScroll]);

    const handleProductClick = useCallback((product: IFeaturedProduct) => {
        router.push({
            pathname: Routes.Products.Details,
            query: {slug: product.slug}
        }).then();
    }, [router]);

    return (
        <motion.section className={classes.outerContainer} ref={constraints}>
            <motion.div
                className={classes.innerContainer}
                drag={'x'}
                dragDirectionLock={true}
                dragElastic={0.15}
                onDragStart={() => setDisableScroll(true)}
                onDragEnd={() => setDisableScroll(false)}
                style={{x}}
                dragConstraints={constraints}>
                {products.map(product => (
                    <FeaturedProduct
                        key={product.name}
                        product={product}
                        onProductClick={handleProductClick}
                        dragPosition={x} />
                ))}
            </motion.div>
        </motion.section>
    )
}

interface IFeaturedProductProps {
    product: IFeaturedProduct;
    dragPosition: MotionValue;
    onProductClick: (product: IFeaturedProduct) => void;
}

const FeaturedProduct = ({product, dragPosition, onProductClick}: IFeaturedProductProps) => {
    const classes = productStyles();
    const [dragX, setDragX] = useDebounce<number>(0, 3, true);
    const [mouseDownX, setMouseDownX] = useState<number|null>(null);

    useEffect(() => {
        dragPosition.onChange(latest => setDragX(latest))
    }, [dragPosition, setDragX]);

    const handleMouseDown = () => {
        setMouseDownX(dragX);
    }

    const handleMouseUp = () => {
        //
        // If the drag position is the same (or close to) the position it was when
        // we recorded the mouse down event, treat the event as a link click.
        //
        if(mouseDownX === null) {
            return;
        }

        if(Math.abs(mouseDownX - dragX) <= 3) {
            onProductClick(product);
        }
    };

    const handleAnchorClick = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
    }, []);

    return (
        <motion.div className={classes.tile} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
            <div className={classes.art}>
                <CloudinaryImage
                    imageId={product.cloudinaryImageId}
                    alt={product.name}
                    quality={70}
                    height={product.height}
                    width={product.width}
                    sizes={[260, 520]}/>
            </div>
            <Box mt={1}>
                <Link passHref={true} prefetch={false} href={{
                    pathname: Routes.Products.Details,
                    query: {slug: product.slug}
                }}>
                    <a onClick={handleAnchorClick}>
                        <Typography className={classes.productName} component={"h4"}>
                            {product.name}
                        </Typography>
                    </a>
                </Link>
            </Box>
            <Box>
                <Typography className={classes.productCategory}>
                    {product.category}
                </Typography>
            </Box>
        </motion.div>
    )
}

const containerStyles = makeStyles(() => createStyles({
    outerContainer: {
        display: "flex",
        flexFlow: "row",
        overFlowX: "auto"
    },
    innerContainer: {
        display: "inline-flex",
        flexFlow: "row nowrap"
    }
}));

const productStyles = makeStyles((theme: Theme) => {
    const dark = theme.palette.mode === "dark";
    return createStyles({
        tile: {
            cursor: "pointer",
            width: "300px",
            background: theme.palette.mode === "dark"
                ? `radial-gradient(${lighten(theme.palette.background.paper, 0.15)}, ${lighten(theme.palette.background.paper, 0.02)})`
                : `radial-gradient(${lighten(theme.palette.background.default, 0.25)}, ${darken(theme.palette.background.default, 0.05)})`,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(3),
            "&:first-child": {
                marginLeft: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    marginLeft: theme.spacing(4)
                }
            },
            "&:last-child": {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    marginRight: theme.spacing(4)
                }
            },
            "&+&": {
                marginLeft: theme.spacing(1.5)
            }
        },
        art: {
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
            overflow: "hidden",
            "& img": {
                maxWidth: "100%",
                height: "auto",
                width: "auto",
                maxHeight: "250px",
                objectFit: "contain"
            }
        },
        productName: {
            textAlign: "center",
            fontSize: "110%",
            lineHeight: 1.2,
            color: dark ? theme.palette.primary.light : theme.palette.text.primary
        },
        productCategory: {
            display: "block",
            textAlign: "center",
            color: theme.palette.text.secondary,
            fontSize: theme.typography.caption.fontSize,
            textTransform: "uppercase"
        }
    })
});
