import Head from "next/head";
import {absoluteBaseUrl} from "../../utils/url";
import Config from "../../config";

export default function IndexCard() {
    return (
        <Head key={`index-card`}>
            <meta property="og:url" content={`${absoluteBaseUrl()}`} />
            <meta property="og:type" content="website"/>
            <meta property="og:site_name" content="Offshoot Rentals"/>
            <meta
                property="og:title"
                content={`Equipment rental for still and motion photography - Offshoot Rentals Melbourne`} />
            <meta
                property="og:description"
                content={"Offshoot Rentals is a trusted production partner in Melbourne supplying equipment for a wide range of still and motion photography projects since 2010."} />
            <meta
                property={"og:image"}
                content={`${Config.CloudinaryBaseUrl}/q_50,w_1200,c_limit,f_auto/Backgrounds/alexa_mini_lf_atlas_ana_odjmmt`} />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="offshoot.rentals"/>
            <meta
                property="twitter:url"
                content={`${absoluteBaseUrl()}`} />
            <meta
                name="twitter:title"
                content={`Equipment rental for still and motion photography - Offshoot Rentals Melbourne`} />
            <meta
                name="twitter:description"
                content={"Offshoot Rentals is a trusted production partner in Melbourne supplying equipment for a wide range of still and motion photography projects since 2010."} />
            <meta
                name="twitter:image"
                content={`${Config.CloudinaryBaseUrl}/q_50,w_1200,c_limit,f_auto/Backgrounds/alexa_mini_lf_atlas_ana_odjmmt`} />
        </Head>
    )
}