import {LazyLoadImage, ScrollPosition} from "react-lazy-load-image-component";
import {useState, useCallback, Fragment} from "react";
import AppConfig from "../../config";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import clsx from "clsx";
import Head from "next/head";

interface IExtraProps {
    srcSet?: string;
    wrapperClassName?: string;
}

interface ICloudinaryImageProps {
    /**
     * The public ID as reported by Cloudinary - including the full path.
     */
    imageId: string;
    /**
     * Only two values are used - one for standard DPI screens and the second
     * for high DPI.
     */
    sizes: number[];
    /**
     * Image alt text.
     */
    alt: string;
    /**
     * Quality between 10 and 100. Default is 50.
     */
    quality?: number;
    /**
     * Classname for the wrapper span.
     */
    wrapperClassName?: string;
    /**
     * Don't lazy load - show immediately.
     */
    showImmediately?: boolean;
    /**
     * Intrinsic image height.
     */
    height?: number;
    /**
     * Intrinsic image width.
     */
    width?: number;
    /**
     * Invoked when the image is loaded.
     */
    onLoad?: () => void;
    /**
     * Invoked before the placeholder is replaced with the lazy loaded image.
     */
    beforeLoad?: () => void;
    /**
     * Scroll position when tracked by an HOC for performance reasons.
     */
    scrollPosition?: ScrollPosition;
    /**
     * If the image should be loaded as early as possible to help with LCP.
     */
    priority?: boolean;
}

export default function CloudinaryImage({imageId, sizes, alt, quality, priority, showImmediately, height, width, onLoad, beforeLoad, scrollPosition}: ICloudinaryImageProps) {
    const classes = styles();
    const imgQuality = quality ?? 50;
    const [loaded, setLoaded] = useState<boolean>(false);

    const defaultSrc = `${AppConfig.CloudinaryBaseUrl}/q_${imgQuality},w_${sizes[0]},c_limit,f_auto/${imageId}`;

    const props: IExtraProps = {};
    if(sizes.length > 1) {
        props.srcSet = `${defaultSrc}, ${AppConfig.CloudinaryBaseUrl}/q_${imgQuality},w_${sizes[1]},c_limit,f_auto/${imageId} 2x`;
    }

    const handleLoaded = useCallback(() => {
        setLoaded(true);
        onLoad?.();
    }, [onLoad]);

    return (
        <Fragment>
            {priority && (
                <Head>
                    <link rel="preload" as={"image"} href={defaultSrc} imageSrcSet={props.srcSet} />
                </Head>
            )}
            <LazyLoadImage
                key={imageId}
                {...props}
                draggable={false}
                onLoad={handleLoaded}
                beforeLoad={beforeLoad}
                scrollPosition={scrollPosition}
                alt={alt}
                width={width}
                height={height}
                className={clsx({
                    [classes.img]: !priority,
                    [classes.loaded]: !priority && loaded
                })}
                visibleByDefault={priority ?? showImmediately ?? false}
                src={defaultSrc} />
        </Fragment>
    )
}

export const styles = makeStyles((_: Theme) => createStyles({
    img: {
        transition: "opacity 350ms",
        opacity: 0,
    },
    loaded: {
        transition: "opacity 350ms",
        opacity: 1
    }
}));
