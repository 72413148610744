import {Organization} from "schema-dts";
import Head from "next/head";
import {jsonLdScriptProps} from "react-schemaorg";
import {AppOrganisation} from "../../config";

export default function OrganisationSchema() {
    return (
        <Head key={`organisation-schema`}>
            <script {...jsonLdScriptProps<Organization>({
                "@context": "https://schema.org",
                ...AppOrganisation as any
            })} />
        </Head>
    )
}