import {Button, darken, lighten, Theme, Typography, useMediaQuery} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import CloudinaryImage from "./images/CloudinaryImage";
import {useContext} from "react";
import {AuthContext, IAuthContext} from "../context/authContext";
import Link from "next/link";
import Routes from "../routes";
import {createStyles, makeStyles} from "@material-ui/styles";

const BP_1 = 515;
const BP_2 = 750;
const BP_3 = 'lg';
const BP_4 = 'xl';

export default function FeatureTile() {
    const classes = styles();
    const {user} = useContext<IAuthContext>(AuthContext);
    const isMediumDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up(420));
    const isLargeDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up(1200));

    return (
        <section className={classes.grid}>
            <div className={classes.a}>
                <CloudinaryImage
                    imageId={"/REIS/assets/fp_images/car_in_fog_ajtmkb"}
                    sizes={isLargeDevice ? [400,800] : [175, 350]}
                    width={1344}
                    height={896}
                    alt={"A woman standing next to a car in the fog."} />
            </div>
            <div className={classes.b}>
            </div>
            <div className={classes.c}>
            </div>
            <div className={classes.d}>
                <CloudinaryImage
                    imageId={"/REIS/assets/fp_images/1M2A5034_uxhdpl"}
                    sizes={isLargeDevice ? [700,1400] : [350, 700]}
                    width={5000}
                    height={3524}
                    alt={"Alexa Mini LF"} />
            </div>
            <div className={classes.e}>
            </div>
            <div className={classes.f}>
            </div>
            <div className={classes.g}>
                <CloudinaryImage
                    imageId={"/REIS/assets/fp_images/archi_mpimtg"}
                    sizes={isLargeDevice ? [800,1600] : (isMediumDevice ? [300, 600] : [100,200])}
                    width={5016}
                    height={3189}
                    alt={"Architectural building"} />
            </div>
            <div className={classes.h}>
            </div>
            <div className={classes.i}>
            </div>
            <div className={classes.j}>
            </div>
            <div className={classes.k}>
            </div>
            <div className={classes.l}>
                <CloudinaryImage
                    imageId={"/REIS/assets/fp_images/141A6745_ip3nvi"}
                    sizes={isMediumDevice ? [300,600] : [100,200]}
                    width={1080}
                    height={1080}
                    alt={"Focusbug and cforce mini motor on the ARRI Trinity 2"} />
            </div>
            <div className={classes.m}>
                <CloudinaryImage
                    imageId={"/REIS/assets/fp_images/building_rs_yavrvy"}
                    sizes={isMediumDevice ? [160,320] : [70,140]}
                    width={5616}
                    height={3744}
                    alt={"Brick apartment complex"} />
            </div>
            <div className={classes.n}>
                {isMediumDevice && (
                    <CloudinaryImage
                        imageId={"/REIS/assets/fp_images/1M2A0831-2_bffnkv"}
                        sizes={[310,620]}
                        width={5000}
                        height={3335}
                        alt={"ARRI Trinity 2"} />
                )}
            </div>
            <div className={classes.o}>
            </div>
            <div className={classes.p}>
                {!user && (
                    <Link href={Routes.Accounts.Create} passHref={true} prefetch={false}>
                        <Button startIcon={<Add/>} component={"a"}>
                            New account
                        </Button>
                    </Link>
                )}
            </div>
            <div className={classes.q}>
            </div>
            <div className={classes.r}>
            </div>
            <div className={classes.s}>
            </div>
            <div className={classes.t}>
                <Typography variant={"h1"}>
                    Media production equipment rental.
                </Typography>
            </div>
            <div className={classes.u}>
                <CloudinaryImage
                    imageId={"/REIS/assets/fp_images/conservatory_ixnc7z"}
                    sizes={isMediumDevice ? [350,700] : [130,260]}
                    width={1456}
                    height={816}
                    alt={"Conservatory scene"} />
            </div>
            <div className={classes.v}>
            </div>
            <div className={classes.w}>
            </div>
            <div className={classes.x}>
            </div>
            <div className={classes.y}>
            </div>
            <div className={classes.z}>
                {isMediumDevice && (
                    <CloudinaryImage
                        imageId={"/REIS/assets/fp_images/archi2_mi4din"}
                        sizes={[535,1070]}
                        width={3750}
                        height={2500}
                        alt={"Architecture"} />
                )}
            </div>
        </section>
    )
}

const styles = makeStyles((theme: Theme) => {
    const baseColourLight = lighten("#c7a600", 0.2);
    const baseColourDark = darken(theme.palette.primary.dark, 0.2);
    const dark = theme.palette.mode === "dark";
    return createStyles({
        "@keyframes float-single": {
            "0%": {
                transform: "translate3d(0, 0, 0) scale3d(1,1,1)"
            },
            "25%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.3,1.3,1.3)"
            },
            "75%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.3,1.3,1.3)",
            }
        },
        "@keyframes float-double": {
            "0%": {
                transform: "translate3d(0, 0, 0) scale3d(1,1,1)"
            },
            "25%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.1,1.1,1.1)"
            },
            "75%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.1,1.1,1.1)",
            }
        },
        "@keyframes float-i": {
            "0%": {
                transform: "translate3d(0, 0, 0) scale3d(1,1,1)"
            },
            "25%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.15,1.2,1.15)"
            },
            "75%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.15,1.2,1.15)",
            }
        },
        "@keyframes float-y": {
            "0%": {
                transform: "translate3d(0, 0, 0) scale3d(1.1,1.1,1.1)"
            },
            "25%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.15,1.25,1.25)"
            },
            "75%": {
                transform: "translate3d(-2px, 2px, 0) scale3d(1.15,1.25,1.25)",
            }
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(20, 1fr)",
            gridTemplateRows: "repeat(20, 25px)",
            gap: 0,
            justifyContent: "stretch",
            gridTemplateAreas: `
            ". e t t t t t t t t t t t t t t t t b ." 
            "i e t t t t t t t t t t t t t t t t b j"
            ". e t t t t t t t t t t t t t t t t b ."
            "f d d d d d d d d d d d d d d d d k . ."
            "f d d d d d d d d d d d d d d d d m m m"
            ". d d d d d d d d d d d d d d d d m m m"
            ". d d d d d d d d d d d d d d d d o . ."
            ". d d d d d d d d d d d d d d d d l l l"
            ". d d d d d d d d d d d d d d d d l l l"
            "h d d d d d d d d d d d d d d d d s . ."
            "h d d d d d d d d d d d d d d d d g g g"
            "c c p p p p p p p p p p p p p r r g g g"
            "c c p p p p p p p p p p p p p . . . . ."
            ". a a a a a a a a a q q u u u u u u u ."
            ". a a a a a a a a a . . u u u u u u u ."
            ". a a a a a a a a a . . u u u u u u u v"
            ". a a a a a a a a a . . . . . . . w w v"
            ". . y y y . . x x . . . . . . . . . . ."
            ". . . . . . . . . . . . . . . . . . . ."
            ". . . . . . . . . . . . . . . . . . . ."
        `,
            [theme.breakpoints.up(BP_1)]: {
                gridTemplateAreas: `
                ". e t t t t t t t t t t t t t b b b b ." 
                "i e t t t t t t t t t t t t t b b b b j"
                ". e t t t t t t t t t t t t t b b b b ."
                "f d d d d d d d d d d d d d k . . . . ."
                "f d d d d d d d d d d d d d m m m m m ."
                ". d d d d d d d d d d d d d m m m m m ."
                ". d d d d d d d d d d d d d m m m m m ."
                ". d d d d d d d d d d d d d o . . . . ."
                ". d d d d d d d d d d d d d l l l l l l"
                ". d d d d d d d d d d d d d l l l l l l"
                ". d d d d d d d d d d d d d l l l l l l"
                "h d d d d d d d d d d d d d l l l l l l"
                "h d d d d d d d d d d d d d s r r g g g"
                "c c p p p p p p p p p p p p p r r g g g"
                "c c p p p p p p p p p p p p p . . g g g"
                ". a a a a a a a a a q q u u u u u u u ."
                ". a a a a a a a a a . . u u u u u u u ."
                ". a a a a a a a a a . . u u u u u u u v"
                ". a a a a a a a a a . . . . . . . w w v"
                ". . y y y . . x x . . . . . . . . . . ."
            `,
            },
            [theme.breakpoints.up(BP_2)]: {
                gridTemplateAreas: `
                ". e . . . . . . . . . g g g g g g g g ." 
                ". e . b b m m m m . . g g g g g g g g ."
                "f a a a c m m m m . i g g g g g g g g ."
                ". a a a c m m m m h h g g g g g g g g j"
                ". a a a d d d d d d d d d d k k k . o o"
                ". a a a d d d d d d d d d d p p p p p p"
                ". a a a d d d d d d d d d d p p p p p p"
                "t t t t d d d d d d d d d d n n n n n ."
                "t t t t d d d d d d d d d d n n n n n ."
                "t t t t d d d d d d d d d d n n n n n ."
                "t t t t d d d d d d d d d d n n n n n ."
                "t t t t d d d d d d d d d d . s s s . ."
                "u u u u d d d d d d d d d d l l l l l w"
                "u u u u d d d d d d d d d d l l l l l w"
                "u u u u d d d d d d d d d d l l l l l w"
                "u u u u v . . . . . . r r r l l l l l w"
                "u u u u v . . . . . . . . . l l l l l w"
                ". . . y . . . . . . . . . . l l l l l w"
                ". . . . . . . . . . . . . . q . . . x w"
                ". . . . . . . . . . . . . . . . . . . ."
            `,
            },
            [theme.breakpoints.up(BP_3)]: {
                gridTemplateAreas: `
                ". e . . . . . . . g g g g g g g . . . ." 
                ". e . b b m m . . g g g g g g g . . . ."
                "f a a a c m m . i g g g g g g g . . . ."
                ". a a a c m m h h g g g g g g g . . . ."
                ". a a a d d d d d d d d j . k k k . o o"
                ". a a a d d d d d d d d p p p n n n n ."
                ". a a a d d d d d d d d p p p n n n n ."
                ". . t t d d d d d d d d z z z n n n n ."
                ". . t t d d d d d d d d z z z n n n n ."
                ". . t t d d d d d d d d z z z n n n n ."
                ". . t t d d d d d d d d z z z n n n n ."
                ". v t t d d d d d d d d z z z n n n n ."
                "u u u u d d d d d d d d z z z s . . . ."
                "u u u u d d d d d d d d z z z l l l . ."
                "u u u u d d d d d d d d z z z l l l . ."
                "u u u u d d d d d d d d z z z l l l . ."
                ". . y w d d d d d d d d r . . l l l . ."
                ". . . w d d d d d d d d r . . l l l . ."
                ". . . w x . . . . . . q . . . l l l . ."
                ". . . . . . . . . . . . . . . . . . . ."
            `,
            },
            [theme.breakpoints.up(BP_4)]: {
                gridTemplateRows: "repeat(20, 35px)",
            }
        },
        a: {
            gridArea: "a",
            overflow: "hidden",
            "& img": {
                maxWidth: "105%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "0 -5px",
                [theme.breakpoints.up(400)]: {
                    maxWidth: "110%",
                    objectPosition: "-10px -20px"
                },
                [theme.breakpoints.up(425)]: {
                    maxWidth: "113%",
                    objectPosition: "-20px -30px"
                },
                [theme.breakpoints.up(450)]: {
                    maxWidth: "115%",
                    objectPosition: "-20px -40px"
                },
                [theme.breakpoints.up(480)]: {
                    objectPosition: "-20px -50px"
                },
                [theme.breakpoints.up(510)]: {
                    objectPosition: "-20px -70px"
                },
                [theme.breakpoints.up(570)]: {
                    objectPosition: "-20px -80px"
                },
                [theme.breakpoints.up(637)]: {
                    objectPosition: "-20px -90px"
                },
                [theme.breakpoints.up(687)]: {
                    objectPosition: "-20px -95px"
                },
                [theme.breakpoints.up(700)]: {
                    objectPosition: "-20px -100px"
                },
                [theme.breakpoints.up(720)]: {
                    objectPosition: "-20px -105px"
                },
                [theme.breakpoints.up(BP_2)]: {
                    maxWidth: "182%",
                    objectPosition: "-70px 0px"
                },
                [theme.breakpoints.up(770)]: {
                    objectPosition: "-78px 0px"
                },
                [theme.breakpoints.up(800)]: {
                    objectPosition: "-85px -10px"
                },
                [theme.breakpoints.up(895)]: {
                    objectPosition: "-90px -15px"
                },
                [theme.breakpoints.up(930)]: {
                    objectPosition: "-100px -30px"
                },
                [theme.breakpoints.up(1020)]: {
                    objectPosition: "-110px -40px"
                },
                [theme.breakpoints.up(1080)]: {
                    objectPosition: "-110px -60px"
                },
                [theme.breakpoints.up(BP_3)]: {
                    maxWidth: "130%",
                    objectPosition: "-50px -20px"
                },
                [theme.breakpoints.up(1315)]: {
                    objectPosition: "-50px -35px"
                },
                [theme.breakpoints.up(1450)]: {
                    objectPosition: "-60px -45px"
                },
                [theme.breakpoints.up(BP_4)]: {
                    objectPosition: "-60px -10px"
                },
                [theme.breakpoints.up(1625)]: {
                    objectPosition: "-70px -20px"
                },
                [theme.breakpoints.up(1710)]: {
                    objectPosition: "-70px -35px"
                },
                [theme.breakpoints.up(1830)]: {
                    objectPosition: "-80px -55px"
                },
                [theme.breakpoints.up(2000)]: {
                    objectPosition: "-80px -65px"
                }
            },
        },
        b: {
            gridArea: "b",
            backgroundColor: dark
                ? baseColourDark
                : darken(baseColourLight, 0.25),
            [theme.breakpoints.up(BP_1)]: {
                animation: "$float-double 10s ease-in-out infinite alternate",
            }
        },
        c: {
            gridArea: "c",
            backgroundColor: dark
                ? baseColourDark
                : darken(baseColourLight, 0.25),
            animation: "$float-double 10s ease-in-out infinite alternate",
        },
        d: {
            gridArea: "d",
            "& img": {
                maxWidth: "125%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "-30px -5px",
                filter: dark ? undefined : "saturate(70%)",
                [theme.breakpoints.up(445)]: {
                    objectPosition: "-20px -10px",
                },
                [theme.breakpoints.up(480)]: {
                    objectPosition: "-20px -20px",
                },
                [theme.breakpoints.up(BP_1)]: {
                    objectPosition: "-15px -10px",
                },
                [theme.breakpoints.up(BP_2)]: {
                    maxWidth: "140%",
                    objectPosition: "-35px -5px"
                },
                [theme.breakpoints.up(865)]: {
                    objectPosition: "-35px -15px",
                },
                [theme.breakpoints.up(960)]: {
                    objectPosition: "-50px -25px",
                },
                [theme.breakpoints.up(1020)]: {
                    objectPosition: "-50px -40px",
                },
                [theme.breakpoints.up(1080)]: {
                    objectPosition: "-90px -70px",
                },
                [theme.breakpoints.up(BP_3)]: {
                    objectPosition: "0 0",
                    maxWidth: "110%",
                }
            },
            overflow: "hidden",
            [theme.breakpoints.up(BP_2)]: {
                transform: "scale3d(1.05,1.05,1.05)"
            },
        },
        e: {
            gridArea: "e",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0),
            [theme.breakpoints.up(BP_2)]: {
                animation: "$float-single 8s ease-in-out infinite",
            }
        },
        f: {
            gridArea: "f",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0.25),
            animation: "$float-single 10s ease-in-out infinite alternate",
        },
        g: {
            gridArea: "g",
            overflow: "hidden",
            "& img": {
                maxWidth: "100%",
                height: "auto",
                objectFit: "cover",
            },
            [theme.breakpoints.up(BP_3)]: {
                "& img": {
                    objectPosition: "0 -60px"
                },
            }
        },
        h: {
            gridArea: "h",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0),
            [theme.breakpoints.up(BP_2)]: {
                animation: "$float-double 10s ease-in-out infinite alternate",
            }
        },
        i: {
            gridArea: "i",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0),
            [theme.breakpoints.up(BP_2)]: {
                animation: "$float-i 10s ease-in-out infinite alternate",
            }
        },
        j: {
            gridArea: "j",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0.25)
        },
        k: {
            gridArea: "k",
            backgroundColor: dark
                ? baseColourDark
                : darken(baseColourLight, 0),
            animation: "$float-single 10s ease-in-out infinite alternate",
        },
        l: {
            gridArea: "l",
            overflow: "hidden",
            "& img": {
                maxWidth: "130%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "0 0px",
                [theme.breakpoints.up(BP_1)]: {
                    objectPosition: "-10px -25px"
                },
                [theme.breakpoints.up(630)]: {
                    objectPosition: "-10px -35px"
                },
                [theme.breakpoints.up(BP_2)]: {
                    maxWidth: "160%",
                    objectPosition: "-30px -32px"
                },
                [theme.breakpoints.up(868)]: {
                    objectPosition: "-40px -42px"
                },
                [theme.breakpoints.up(914)]: {
                    objectPosition: "-40px -62px"
                },
                [theme.breakpoints.up(995)]: {
                    objectPosition: "-40px -72px"
                },
                [theme.breakpoints.up(1050)]: {
                    objectPosition: "-40px -82px"
                },
                [theme.breakpoints.up(BP_3)]: {
                    maxWidth: "130%",
                    objectPosition: "0 -22px"
                },
                [theme.breakpoints.up(1920)]: {
                    objectPosition: "0 -32px"
                }
            },
        },
        m: {
            gridArea: "m",
            overflow: "hidden",
            "& img": {
                maxWidth: "150%",
                height: "auto",
                objectFit: "cover",
                [theme.breakpoints.up(BP_3)]: {

                }
            },
        },
        n: {
            gridArea: "n",
            overflow: "hidden",
            "& img": {
                maxWidth: "130%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "0 0",
                filter: dark ? "saturate(80%)" : "hue-rotate(10deg)"
            },
            display: "none",
            [theme.breakpoints.up(BP_2)]: {
                display: "block"
            },
            [theme.breakpoints.up(830)]: {
                "& img": {
                    objectPosition: "0 0"
                }
            },
            [theme.breakpoints.up(955)]: {
                "& img": {
                    objectPosition: "0 0"
                }
            },
            [theme.breakpoints.up(1055)]: {
                "& img": {
                    objectPosition: "0 0"
                }
            },
            [theme.breakpoints.up(BP_3)]: {
                "& img": {
                    objectPosition: "-30px 0"
                }
            }
        },
        o: {
            gridArea: "o",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0.25)
        },
        p: {
            gridArea: "p",
            display: "flex",
            justifyContent: "center",
            backgroundColor: dark
                ? baseColourDark
                : lighten(baseColourLight, 0.25),
            padding: theme.spacing(),
            "& .MuiButton-root": {
                color: theme.palette.common.black,
                padding: theme.spacing(0.5, 1)
            },
            textAlign: "center"
        },
        q: {
            gridArea: "q",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0)
        },
        r: {
            gridArea: "r",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0.25),
            [theme.breakpoints.up(BP_2)]: {
                animation: "$float-double 8s ease-in-out infinite alternate",
            }
        },
        s: {
            gridArea: "s",
            backgroundColor: dark
                ? baseColourDark
                : lighten(baseColourLight, 0.25),
            animation: "$float-single 7s ease-in-out infinite alternate",
        },
        t: {
            gridArea: "t",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : lighten(baseColourLight, 0.25),
            padding: theme.spacing(1.6, 2.2, 1.6, 1.6),
            color: dark ? lighten(baseColourDark, 0.55) : darken(baseColourLight, 0.65),
            display: "flex",
            alignItems: "center",
            "& .MuiTypography-root": {
                display: "block",
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: "1.25rem",
                maxWidth: "100%",
                [theme.breakpoints.up(725)]: {
                    fontSize: "1.4rem",
                },
                [theme.breakpoints.up(BP_2)]: {
                    fontSize: "1.2rem",
                },
                [theme.breakpoints.up(953)]: {
                    fontSize: "1.35rem",
                },
                [theme.breakpoints.up(1055)]: {
                    fontSize: "1.37rem",
                },
                [theme.breakpoints.up(BP_3)]: {
                    fontSize: "1rem",
                },
                [theme.breakpoints.up(1318)]: {
                    fontSize: "1.1rem",
                },
                [theme.breakpoints.up(1400)]: {
                    fontSize: "1.2rem",
                },
                [theme.breakpoints.up(1600)]: {
                    fontSize: "1.4rem",
                },
                [theme.breakpoints.up(1720)]: {
                    fontSize: "1.5rem",
                },
                [theme.breakpoints.up(1920)]: {
                    fontSize: "1.6rem",
                },
            }
        },
        u: {
            gridArea: "u",
            overflow: "hidden",
            "& img": {
                maxWidth: "160%",
                height: "auto",
                objectPosition: "-20px -30px",
                [theme.breakpoints.up(400)]: {
                    maxWidth: "185%",
                    objectPosition: "-40px -60px"
                },
                [theme.breakpoints.up(450)]: {
                    objectPosition: "-45px -70px"
                },
                [theme.breakpoints.up(498)]: {
                    objectPosition: "-45px -75px"
                },
                [theme.breakpoints.up(530)]: {
                    objectPosition: "-50px -80px"
                },
                [theme.breakpoints.up(560)]: {
                    objectPosition: "-50px -85px"
                },
                [theme.breakpoints.up(580)]: {
                    objectPosition: "-60px -90px"
                },
                [theme.breakpoints.up(630)]: {
                    maxWidth: "185%",
                    objectPosition: "-70px -95px"
                },
                [theme.breakpoints.up(660)]: {
                    maxWidth: "185%",
                    objectPosition: "-70px -100px"
                },
                [theme.breakpoints.up(695)]: {
                    maxWidth: "185%",
                    objectPosition: "-75px -105px"
                },
                [theme.breakpoints.up(720)]: {
                    maxWidth: "185%",
                    objectPosition: "-75px -110px"
                },
                [theme.breakpoints.up(BP_2)]: {
                    maxWidth: "160%",
                    objectPosition: "-40px 0"
                },
                [theme.breakpoints.up(870)]: {
                    maxWidth: "160%",
                    objectPosition: "-40px -20px"
                },
                [theme.breakpoints.up(940)]: {
                    objectPosition: "-40px -30px"
                },
                [theme.breakpoints.up(1000)]: {
                    objectPosition: "-40px -40px"
                },
                [theme.breakpoints.up(1080)]: {
                    objectPosition: "-40px -60px"
                },
                [theme.breakpoints.up(BP_3)]: {
                    maxWidth: "140%",
                    objectPosition: "-30px -75px"
                },
                [theme.breakpoints.up(1340)]: {
                    objectPosition: "-30px -85px"
                },
                [theme.breakpoints.up(1390)]: {
                    objectPosition: "-30px -95px"
                },
                [theme.breakpoints.up(1480)]: {
                    objectPosition: "-30px -105px"
                },
                [theme.breakpoints.up(BP_3)]: {
                    maxWidth: "150%",
                    objectPosition: "-40px -90px"
                },
                [theme.breakpoints.up(1670)]: {
                    objectPosition: "-40px -120px"
                },
                [theme.breakpoints.up(1800)]: {
                    objectPosition: "-40px -130px"
                },
                [theme.breakpoints.up(1980)]: {
                    objectPosition: "-40px -150px"
                },
            }
        },
        v: {
            gridArea: "v",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0),
            [theme.breakpoints.down(BP_1)]: {
                animation: "$float-single 12s ease-in-out infinite alternate",
            }
        },
        w: {
            gridArea: "w",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0),
            animation: "$float-double 12s ease-in-out infinite alternate",
        },
        x: {
            gridArea: "x",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0),
            animation: "$float-single 12s ease-in-out infinite alternate",
        },
        y: {
            gridArea: "y",
            animation: "$float-y 12s ease-in-out infinite alternate",
            backgroundColor: dark
                ? darken(baseColourDark, 0.2)
                : darken(baseColourLight, 0)
        },
        z: {
            gridArea: "z",
            overflow: "hidden",
            "& img": {
                objectFit: "cover",
                maxWidth: "250%",
                height: "auto",
                objectPosition: "0 0"
            },
            display: "none",
            [theme.breakpoints.up(BP_3)]: {
                display: "block",

            }
        },
        contactDetail: {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            "&+&": {
                marginLeft: theme.spacing()
            }
        }
    })
});
